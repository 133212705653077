import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Field, Modal, Option, Select, Stack, Title } from "@introist/react-foundation/v2";
import { TriggerEvent, useTriggerActions, useTriggerEvents } from "../../hooks/useTrigger";
import { Trigger } from "../../hooks/useTriggers";
import { DataSource, useDataSources } from "../../../datasources/hooks/useDataSources";
import { DataSourceIcon } from "../../../datasources/components/DataSourceIcon";
import { FormFooter } from "../../../datasources/components/FormFooter";
import { SetupCard } from "./SetupCard";

export const EventEditor = ({ trigger }: { trigger: Trigger }) => {
  const { update } = useTriggerActions();

  const { dataSources } = useDataSources();
  const { availableEvents } = useTriggerEvents();

  const [open, setOpen] = useState(false);

  const [data, setData] = useState<Partial<TriggerEvent>>({});

  useEffect(() => {
    setData(trigger.triggerEvent ?? {});
  }, [trigger]);

  const eventSources: DataSource[] = useMemo(() => {
    const eventDss = (availableEvents ?? []).map(e => e.source);

    const sources = [
      {
        id: "introist",
        sourceType: "introist",
        title: "Introist"
      } as unknown as DataSource,
      ...(dataSources ?? [])
    ];

    return sources.filter(s => eventDss.includes(s.sourceType));
  }, [availableEvents, dataSources]);

  const sourceOptions: Option[] = useMemo(() => {
    return eventSources.map(ds => ({
      key: ds.id,
      title: ds.name,
      startAdornment: <DataSourceIcon dataSource={ds.sourceType} size="small" />
    }));
  }, [eventSources]);

  const eventOptions = useMemo(() => {
    const ds = eventSources.find(ds => ds.id === data.datasourceId);

    return (
      (availableEvents ?? [])
        .filter(e => e.source === ds?.sourceType)
        .map(e => ({
          key: e.event,
          title: e.title
        })) ?? []
    );
  }, [data, availableEvents, eventSources]);

  const onSave = useCallback(async () => {
    await update(trigger.id, { triggerEvent: data as TriggerEvent, type: "event" }).then(() => {
      setOpen(false);
    });
  }, [data, trigger, update]);

  return (
    <>
      {!trigger.triggerEvent && (
        <SetupCard title="Setup event" icon="targetAfter" onClick={() => setOpen(true)} />
      )}
      {trigger.triggerEvent && (
        <>
          <TriggerEventCard triggerEvent={trigger.triggerEvent} onClick={() => setOpen(true)} />
        </>
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Edit event"
        style={{ width: "350px" }}
      >
        <Stack vertical>
          <Field title="Source">
            <Select
              options={sourceOptions}
              value={data?.datasourceId!}
              onSelect={opt => setData({ ...data, datasourceId: opt.key, event: undefined })}
              placeholder="Select data source"
              style={{ width: "100%" }}
            />
          </Field>
          <Field title="Event">
            <Select
              size="small"
              options={eventOptions}
              placeholder="Select event"
              disabled={!data?.datasourceId}
              style={{ width: "100%" }}
              value={data?.event}
              onSelect={opt => setData({ ...data, event: opt.key })}
            />
          </Field>
          <FormFooter onCancel={() => setOpen(false)} onSubmit={onSave} />
        </Stack>
      </Modal>
    </>
  );
};

const TriggerEventCard = ({
  triggerEvent,
  onClick
}: {
  triggerEvent: TriggerEvent;
  onClick: () => void;
}) => {
  const { dataSources } = useDataSources();
  const { availableEvents } = useTriggerEvents();

  const ds = useMemo(() => {
    if (triggerEvent.datasourceId === "introist")
      return { sourceType: "introist", name: "Introist" };
    return (dataSources ?? []).find(ds => ds.id === triggerEvent.datasourceId);
  }, [triggerEvent, dataSources]);

  const event = useMemo(() => {
    return (availableEvents ?? []).find(
      e => e.source === ds?.sourceType && e.event === triggerEvent.event
    );
  }, [triggerEvent, availableEvents, ds]);

  return (
    <Card
      onClick={onClick}
      style={{ width: "100%", height: "100%", padding: "var(--spacing-xLarge)" }}
    >
      <Stack vertical alignItems="center" justifyContent="center" style={{ height: "100%" }}>
        <Stack>
          {ds && <DataSourceIcon dataSource={ds.sourceType} />}
          <Stack gap="small" style={{ flexWrap: "wrap" }}>
            <Title variant="bold">{event?.title ?? triggerEvent.event}</Title>
            <Title> in {ds?.name ?? "Unknown data source"}</Title>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};
