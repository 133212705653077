import { Block } from "components/atoms";
import { StepEditorAccordion } from "../../StepEditorAccordion";
import { DataForm, Icon, Select } from "@introist/react-foundation/v2";
import { WorkflowStep } from "services/api/WorkflowApi";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { CompanyEventsContextProvider } from "modules/events/context";

import { prefixForm } from "utils/FormUtils";
import { CompanyEventSelector } from "./CompanyEventSelector";
import { AfterSelector, BeforeSelector } from "./AfterSelector";
import { useAttributes } from "../../../../../../hooks";

interface Props {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formPrefix: string;
  readOnly?: boolean;
  targetDateTerm?: string;
}

export const AttendToEventEditor = ({ form, formPrefix, readOnly, targetDateTerm }: Props) => {
  const prefixedForm = prefixForm(form, formPrefix);

  return (
    <CompanyEventsContextProvider>
      <AttendToEventEditorBlock
        companyEventId={prefixedForm.get("companyEventId")}
        onChangeCompanyEventId={prefixedForm.set("companyEventId")}
        after={prefixedForm.get("after")}
        onChangeAfter={prefixedForm.set("after")}
        before={prefixedForm.get("before")}
        onChangeBefore={prefixedForm.set("before")}
        attendeeAttribute={prefixedForm.get("attendeeAttribute")}
        onChangeAttendeeAttribute={prefixedForm.set("attendeeAttribute")}
        targetDateTerm={targetDateTerm}
      />
    </CompanyEventsContextProvider>
  );
};

export const AttendToEventEditorBlock = ({
  companyEventId,
  onChangeCompanyEventId,
  after,
  onChangeAfter,
  before,
  onChangeBefore,
  targetDateTerm,
  attendeeAttribute,
  onChangeAttendeeAttribute
}: {
  companyEventId: string;
  onChangeCompanyEventId: (value: string) => unknown;
  after: string;
  before: string;
  onChangeAfter: (value: string) => unknown;
  onChangeBefore: (value: string | null) => unknown;
  targetDateTerm?: string;
  attendeeAttribute: string;
  onChangeAttendeeAttribute: (value: string) => unknown;
}) => {
  const attributes = useAttributes(false, ["email"]);

  return (
    <Block compact blended>
      <StepEditorAccordion title="Attendance" stepOrder={2}>
        <StepEditorAccordion.Row label="Event">
          <CompanyEventSelector companyEventId={companyEventId} onChange={onChangeCompanyEventId} />
        </StepEditorAccordion.Row>
        <StepEditorAccordion.Row label="Attendee">
          <Select
            searchable
            attachToRef={false}
            element="button"
            startAdornment={<Icon name="person" />}
            variant="blended"
            options={(attributes ?? []).map(a => ({ key: a.variable, title: a.name }))}
            placeholder="Select attendee"
            value={attendeeAttribute}
            onSelect={opt => onChangeAttendeeAttribute(opt.key)}
            onEnter={onChangeAttendeeAttribute}
          />
        </StepEditorAccordion.Row>
        <StepEditorAccordion.Row label="Earliest">
          <AfterSelector
            value={{ reldate: after }}
            onChange={value => {
              onChangeAfter(value.reldate);
            }}
            targetDateTerm={targetDateTerm}
          />
        </StepEditorAccordion.Row>
        <StepEditorAccordion.Row label="Latest">
          <BeforeSelector
            value={before ? { reldate: before } : undefined}
            onChange={value => {
              onChangeBefore(value?.reldate ?? null);
            }}
            targetDateTerm={targetDateTerm}
          />
        </StepEditorAccordion.Row>
      </StepEditorAccordion>
    </Block>
  );
};
