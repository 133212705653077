import { useMemo, useState } from "react";

export type SearchOptions<T> = {
  fields: string[];
  preFilter?: (item: T) => boolean;
};

export const useSearch = <T extends { [index: string]: any }>(
  data?: T[],
  options?: SearchOptions<T>
) => {
  const [search, setSearch] = useState("");

  const filtered = useMemo(() => {
    if (!data) return undefined;
    if (!search) return data;

    const prefiltered = options?.preFilter ? data.filter(options.preFilter) : data;

    return prefiltered.filter(item => {
      for (const field of options?.fields || []) {
        if (String(item[field]).toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
  }, [data, search, options?.fields, options?.preFilter]);

  return {
    filtered,
    search,
    setSearch
  };
};
