import {
  Button,
  IconButton,
  ModalV2,
  Select,
  Stack,
  Title,
  useActions,
  useTheme,
  useToast
} from "@introist/react-foundation/v2";
import { json } from "@codemirror/lang-json";
import { useEffect, useState } from "react";
import { FormFooter } from "../../../modules/datasources/components/FormFooter";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import "./CodeEditor.css";
import { CodeEditor } from "./CodeEditor";
import { api, RouterOutput } from "../../../services/rpc/RpcProvider";
import { EditorView } from "@uiw/react-codemirror";
import { useLocalStorage } from "usehooks-ts";
import { ShortUuid } from "../../../utils/Utils";

export type CodeEditorProps = {
  id?: string;
  title?: string;
  value: string;
  onChange: (value: string) => unknown;
  testTemplates?: { key: string; title: string; value?: string; json?: object }[];
};

type TestData = RouterOutput["system"]["functions"]["run"];

export const FunctionEditor = ({ id, value, onChange, testTemplates }: CodeEditorProps) => {
  const { theme } = useTheme();
  const toast = useToast();

  const [resultRef, setResultRef] = useState<HTMLElement | null>(null);

  const [testData, setTestData] = useLocalStorage<string>(
    `introist-fn-${id ?? ShortUuid.generate()}-td`,
    "{}"
  );

  const test = api.system.functions.run.useMutation();
  const [testResult, setTestResult] = useState<TestData | undefined>();

  return (
    <div style={{ height: "65vh" }}>
      <Allotment separator={false} defaultSizes={[300, 200, 100]}>
        <Allotment.Pane>
          <Stack
            gap="xSmall"
            vertical
            style={{ padding: "var(--spacing-small)", boxSizing: "border-box", height: "100%" }}
          >
            <Title>Code</Title>
            <CodeEditor value={value} onChange={onChange} height="100%" />
          </Stack>
        </Allotment.Pane>
        <Allotment.Pane>
          <Stack
            gap="xSmall"
            vertical
            style={{ padding: "var(--spacing-small)", boxSizing: "border-box", height: "100%" }}
          >
            <Title>Test data</Title>
            <CodeEditor
              value={testData}
              extensions={[json()]}
              onChange={setTestData}
              height="100%"
            />
            <Stack>
              {testTemplates && (
                <Select
                  searchable={testTemplates.length > 10}
                  element="button"
                  variant="blended"
                  placeholder="Use template"
                  size="small"
                  options={testTemplates}
                  onSelect={opt => {
                    const template = testTemplates.find(t => t.key === opt.key);
                    if (template!.json) {
                      setTestData(JSON.stringify(template!.json, null, 2));
                    } else {
                      setTestData(template!.value ?? "{}");
                    }
                  }}
                />
              )}
              <Button
                startIcon="playCircle"
                size="small"
                variant="outlined"
                style={{ marginLeft: "auto" }}
                onClickWithLoading={async () => {
                  setTestResult(undefined);
                  await test
                    .mutateAsync({ fn: value, args: JSON.parse(testData) })
                    .then(res => {
                      setTestResult(res);
                      setTimeout(() => resultRef?.scrollIntoView({ behavior: "smooth" }), 200);
                    })
                    .catch(() => {
                      toast.error("Failed to run function");
                    });
                }}
              >
                Test
              </Button>
            </Stack>
          </Stack>
        </Allotment.Pane>
        <Allotment.Pane>
          <Stack
            gap="xSmall"
            vertical
            style={{ padding: "var(--spacing-small)", boxSizing: "border-box", height: "100%" }}
          >
            <Title>Log</Title>
            <Stack
              vertical
              gap="xSmall"
              style={{
                borderRadius: "8px",
                background: theme.palette.surface.dimmed,
                height: "100%",
                width: "100%",
                margin: "var(--spacing-small)",
                boxSizing: "border-box",
                overflowY: "auto"
              }}
            >
              {!testResult && !test.isLoading && (
                <Title style={{ margin: "auto" }}>Run Test to see logs</Title>
              )}
              {test.isLoading && <Title style={{ margin: "auto" }}>Running test...</Title>}
              {testResult &&
                testResult.logs?.map((log, i) => (
                  <Title key={i} style={{ padding: "0 var(--spacing-small)" }}>
                    {log}
                  </Title>
                ))}

              {testResult && (
                <CodeEditor
                  extensions={[json(), EditorView.lineWrapping]}
                  readOnly
                  value={JSON.stringify((testResult as any).result)}
                  style={{
                    borderRadius: 8,
                    height: "100%"
                  }}
                />
              )}
              <div ref={setResultRef} />
            </Stack>
          </Stack>
        </Allotment.Pane>
      </Allotment>
    </div>
  );
};

export const CodeEditorModal = ({
  title,
  open,
  onClose,
  onSave,
  onRemove,
  ...props
}: Omit<CodeEditorProps, "onChange"> & {
  title?: string;
  open: boolean;
  onClose: () => unknown;
  onSave: (value: string) => unknown;
  onRemove?: () => unknown;
}) => {
  const { onConfirmAction } = useActions();

  const [internalValue, setInternalValue] = useState(props.value);

  useEffect(() => {
    setInternalValue(props.value);
  }, [props.value]);

  const remove = onConfirmAction(
    async () => {
      await onRemove!();
      onClose();
    },
    {
      title: "Remove function",
      description: "Are you sure you want to remove this function?"
    }
  );

  return (
    <ModalV2
      open={open}
      onClose={onClose}
      title={title}
      maxWidth={1600}
      footer={
        <FormFooter
          slim
          onCancel={onClose}
          onSubmit={() => Promise.resolve(onSave(internalValue))}
          left={onRemove && <IconButton icon="trash" size="large" onClick={remove} />}
        />
      }
    >
      <FunctionEditor {...props} value={internalValue} onChange={setInternalValue} />
    </ModalV2>
  );
};
