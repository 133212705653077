import { useAutomation, useAutomationActions } from "../hooks/useAutomations";
import {
  Button,
  Drawer,
  H4,
  Icon,
  IconButton,
  Select,
  Stack,
  Title,
  Tooltip,
  useTheme,
  useToast
} from "@introist/react-foundation/v2";
import { useEffect, useMemo, useState } from "react";
import { EmployeeField, useEmployeeFields } from "../../employees/hooks/useEmployeeFields";
import styled from "styled-components";
import { EmployeeProfileField } from "../../employees/components/EmployeeProfileField";
import { isEmpty, omit, pick } from "lodash";
import { UnsavedChanges } from "../../../components/molecules";
import { useEmployees } from "../../employees/hooks/useEmployees";
import { Empty } from "../../../foundation";

const LimitTitle = styled.div`
  h5 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const FieldRow = styled(Stack)`
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: transparent;

  :hover {
    border-color: var(--palette-border-dimmed);
  }
`;

export const AutomationData = ({ id }: { id: string }) => {
  const { theme } = useTheme();
  const toast = useToast();

  const { fields } = useEmployeeFields();

  const employeeFields = useMemo(() => {
    if (!fields) return [];
    return fields.filter(f => f.type === "employee");
  }, [fields]);

  const { employees } = useEmployees({ fields: (employeeFields ?? []).map(f => f.linkingField!) });

  const { automation } = useAutomation({ id });
  const { update } = useAutomationActions();

  const [fieldData, setFieldData] = useState<{ [k: string]: any }>({});

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setFieldData(automation?.data ?? {});
  }, [automation, open]);

  const dataCount = useMemo(() => {
    return Object.keys(automation?.data ?? {}).length;
  }, [automation]);

  const AddFieldSelect = (
    <Select
      searchable
      element="button"
      variant="outlined"
      startAdornment={<Icon name="plus" />}
      placeholder="Add Automation Field"
      options={(fields ?? []).map(f => pick(f, "key", "title"))}
      onSelect={opt => setFieldData({ ...fieldData, [opt.key]: "" })}
    >
      Add Automation Field
    </Select>
  );

  if (!automation) return null;

  return (
    <>
      <Button variant="blended" onClick={() => setOpen(true)}>
        {dataCount
          ? `${dataCount} automation field${dataCount > 1 ? "s" : ""}`
          : "No automation data"}
      </Button>
      <Drawer open={open} onClose={() => setOpen(false)} position="right" width="540px">
        <Stack vertical style={{ padding: theme.spacing.large }}>
          <Stack justifyContent="space-between">
            <H4>Automation Data</H4>
            <IconButton icon="crossSmall" onClick={() => setOpen(false)} />
          </Stack>
          <Stack vertical gap="none">
            {isEmpty(fieldData) && (
              <Empty title="No automation data" message="" action={AddFieldSelect} />
            )}
            {Object.keys(fieldData)
              .sort()
              .map(key => {
                const field =
                  (fields ?? []).find(f => f.key === key) ??
                  ({
                    key: key,
                    title: "Unknown field",
                    type: "text"
                  } as EmployeeField);

                return (
                  <FieldRow
                    justifyContent="center"
                    style={{
                      padding: theme.spacing.large
                    }}
                  >
                    <LimitTitle style={{ width: "30%" }}>
                      <Tooltip tooltip={field.title}>
                        <Title>{field.title}</Title>
                      </Tooltip>
                    </LimitTitle>

                    <EmployeeProfileField
                      field={field}
                      value={fieldData[key] ?? null}
                      onChange={newVal =>
                        setFieldData({
                          ...fieldData,
                          [key]: newVal
                        })
                      }
                      error={undefined}
                      employees={employees ?? []}
                      onClear={() => setFieldData(omit(fieldData, key))}
                    />
                  </FieldRow>
                );
              })}
          </Stack>
          {!isEmpty(fieldData) && <div>{AddFieldSelect}</div>}
          <UnsavedChanges
            show={(automation?.data ?? {}) !== fieldData}
            saveTitle="Save"
            onSave={() =>
              update(id, { data: fieldData }).catch(() => {
                toast.error("Failed to update Automation");
              })
            }
            style={{
              position: "sticky",
              bottom: "1rem",
              left: "calc(50% - 125px)",
              width: "250px"
            }}
          />
        </Stack>
      </Drawer>
    </>
  );
};
