import { Icon, IconName, Stack, Title, useTheme } from "@introist/react-foundation/v2";
import { HoverableCard } from "../../../../foundation/blocks/HoverableCard";

export const SetupCard = ({
  title,
  icon,
  onClick
}: {
  title?: string;
  icon?: IconName;
  onClick?: () => unknown;
}) => {
  const { theme } = useTheme();

  return (
    <HoverableCard
      variant="blended"
      style={{ padding: "var(--spacing-xxLarge)", width: "100%" }}
      onClick={onClick}
    >
      <Stack alignItems="center" justifyContent="center">
        {icon && <Icon color={theme.palette.foreground.dimmed} name={icon} />}
        <Title>{title}</Title>
      </Stack>
    </HoverableCard>
  );
};
